.speech-bubble {
	position: relative;
    /* display: flex;
    align-items: flex-end;
    justify-content: center; */
    padding: 10px;
	background: #6fb3f7;
	border-radius: .4em;
    max-width: 200px;
    /*min-height: 100px; */
    box-shadow: 5px 10px;
}

.speech-bubble-left:after {
	content: '';
	position: absolute;
	right: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 15px solid transparent;
	border-left-color: #6fb3f7;
	border-right: 0;
	margin-top: -15px;
	margin-right: -15px;
}

.speech-bubble-right:after {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 15px solid transparent;
	border-right-color: #6fb3f7;
	border-left: 0;
	margin-top: -15px;
	margin-left: -15px;
}