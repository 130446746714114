.App {
    display: flex;
    flex-flow: column;
    min-height: 100vh;
}

.MainContent {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

body {
    background: linear-gradient(rgba(200,200,200,.5), rgba(0,0,0,.5)), url('noise.svg');
}

.DecisionTree {
    background: white;
}

.ClassifierBuilder {
    border: none !important;
    margin-right: 10px;
}