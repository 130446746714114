.NavBar-root {
    background-color: #242424;
    flex: 0 0 8vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.NavBar-list {
    margin: 0px;
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    flex: 1;
    padding: 0px;
}

.NavBar-item {
    display: flex;
    align-items: center;
    border-radius: 6px;
}

.NavBar-button {
    text-decoration: none;
    color: white;
    padding: 10px;
}

.NavBar-item:hover {
    background: grey;
}