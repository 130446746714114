.Publications {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.PublicationsContainer {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.PublicationsContainer h1 {
    padding-top: 80px;
}

.PublicationsContainer li a {
    font-size: x-large;
    padding: 20px;
    display: block;
    color: blue;
}