.NavBar-menu {
    color: white;
    padding: 10px;
    user-select: none;
}

.NavBar-menu-dropdown {
    display: none;
}

.NavBar-menu-dropdown.selected {
    display: flex;
    position: absolute;
    border: 2px solid black;
    background-color: #242424;
    z-index: 10;
}

.NavBar-menu-dropdown ul {
    list-style: none;
    padding: 0;
}

.NavBar-menu-link {
    text-decoration: none;
    color: white;
    padding: 10px;
    display: block;
}

.NavBar-menu-link:hover {
    background-color: grey;
}

.NavBar-item.selected {
    background: grey;
}