footer {
    flex: 0 0 8vh;
    display: flex;
    background-color: #242424;
    align-items: center;
    justify-content: space-between;
}

footer p {
    color: white;
    padding-left: calc(min(3vw, 15px));
}

footer div {
    font-size: 36px;
    margin-right: 1vw;
}

footer a {
    color: gray;
    margin: calc(min(2vw, 15px));
    cursor: pointer;
    text-decoration: none;
}

footer a:hover {
    color: white;
}