.Contact {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.ContactContainer {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 4vw;
    margin-right: 4vw;
}

.ContactContainer h1 {
    margin-top: 20vh;
}