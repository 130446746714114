.Home {
    display: flex;
    flex-flow: column;
    height: 100vh;
}

.HomeContainer {
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    display: flex;
    position: relative;
}

.HomeContainer div {
    position: absolute;
    left: 15%;
    top: 15%;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    padding: 30px;
    overflow: auto;
    max-height: 50vh;
}

.HomeContainer img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: 70% 0;
}

.HomeContainer h1 {
    color: white;
    font-size: 5em;
    margin-top: 0;
    max-width: 30vw;
}

.HomeContainer p {
    /* max-width: 30%; */
    color: white;
    width: 30vw;
    max-width: 30vw;
}

.ContactLink {
    color: rgb(134, 134, 255);
}

@media screen and (max-width: 925px) {
    .HomeContainer h1 {
        font-size: 3em;
    }
}

@media screen and (max-width: 555px) {
    .HomeContainer h1 {
        font-size: 2em;
    }
}